



















import { computed, defineComponent } from '@vue/composition-api';
import Amount from '../Amount.vue';
import { useAddressStore } from '../../stores/Address';
import { usePrestakingStore } from '../../stores/Prestaking';
import TwoLeafStakingIcon from '../icons/Prestaking/TwoLeafStakingIcon.vue';
import LockIcon, { LockIconType } from '../icons/Prestaking/LockIcon.vue';
import OneLeafStakingIcon from '../icons/Prestaking/OneLeafStakingIcon.vue';
import ThreeLeafStakingIcon from '../icons/Prestaking/ThreeLeafStakingIcon.vue';

export default defineComponent({
    setup() {
        const { activePrestake, activeValidator } = usePrestakingStore();
        const { activeAddressInfo } = useAddressStore();

        const currentPercentage = computed(() => {
            const alreadyPrestakedAmount = activePrestake.value?.balance || 0;
            const availableAmount = (activeAddressInfo.value?.balance || 0) + alreadyPrestakedAmount;
            const percent = (100 * alreadyPrestakedAmount) / availableAmount;

            return percent;
        });

        return {
            LockIconType,

            activePrestake,
            activeValidator,
            currentPercentage,
        };
    },
    components: {
        OneLeafStakingIcon,
        TwoLeafStakingIcon,
        ThreeLeafStakingIcon,
        Amount,
        LockIcon,
    },
});
