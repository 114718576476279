












































































































































































































































import { defineComponent, ref, watch } from '@vue/composition-api';
import {
    Identicon,
    GearIcon,
    Copyable,
    ArrowRightSmallIcon,
    ArrowLeftIcon,
    MenuDotsIcon,
    InfoCircleSmallIcon,
} from '@nimiq/vue-components';
// @ts-expect-error missing types for this package
import { Portal } from '@linusborg/vue-simple-portal';
import { BigNumber } from 'ethers';
import { SignPolygonTransactionRequest } from '@nimiq/hub-api';
import { RelayRequest } from '@opengsn/common/dist/EIP712/RelayRequest';
import { ForwardRequest } from '@opengsn/common/dist/EIP712/ForwardRequest';

import BitcoinIcon from '../icons/BitcoinIcon.vue';
import UsdcIcon from '../icons/UsdcIcon.vue';
import Amount from '../Amount.vue';
import FiatConvertedAmount from '../FiatConvertedAmount.vue';
import SearchBar from '../SearchBar.vue';
import TransactionList from '../TransactionList.vue';
import BtcTransactionList from '../BtcTransactionList.vue';
import UsdcTransactionList from '../UsdcTransactionList.vue';
import MobileActionBar from '../MobileActionBar.vue';
import RenameIcon from '../icons/AccountMenu/RenameIcon.vue';
import RefreshIcon from '../icons/RefreshIcon.vue';
import CashlinkButton from '../CashlinkButton.vue';
import PrestakingButton from '../prestaking/PrestakingButton.vue';

import { useAccountStore } from '../../stores/Account';
import { useAddressStore } from '../../stores/Address';
import { useBtcAddressStore } from '../../stores/BtcAddress';
import { useUsdcAddressStore } from '../../stores/UsdcAddress';
import { onboard, rename, swapBridgedUsdcToNative } from '../../hub';
import { useElementResize } from '../../composables/useElementResize';
import { useWindowSize } from '../../composables/useWindowSize';
import { BTC_ADDRESS_GAP, CryptoCurrency, ENV_MAIN } from '../../lib/Constants';
import { checkHistory } from '../../electrum';
import HighFiveIcon from '../icons/HighFiveIcon.vue';
import { useSwapsStore } from '../../stores/Swaps';
import BoxedArrowUpIcon from '../icons/BoxedArrowUpIcon.vue';
import { useConfig } from '../../composables/useConfig';
import {
    calculateFee,
    getPolygonBlockNumber,
    getPolygonClient,
    getSwapContract,
    sendTransaction as sendPolygonTransaction,
} from '../../ethers';
import { POLYGON_BLOCKS_PER_MINUTE } from '../../lib/usdc/OpenGSN';
import { i18n } from '../../i18n/i18n-setup';
import { useUsdcTransactionsStore } from '../../stores/UsdcTransactions';
import HeroIcon from '../icons/Prestaking/HeroIcon.vue';
import PrestakingPreview from '../prestaking/PrestakingPreview.vue';
import { usePrestakingStore } from '../../stores/Prestaking';

export default defineComponent({
    name: 'address-overview',
    setup() {
        const { activeAccountId, activeCurrency } = useAccountStore();
        const { activeAddressInfo, activeAddress } = useAddressStore();
        const { accountBalance: btcAccountBalance } = useBtcAddressStore();
        const {
            accountBalance: usdcAccountBalance,
            nativeAccountBalance: nativeUsdcAccountBalance,
            addressInfo: usdcAddressInfo,
        } = useUsdcAddressStore();
        const { promoBoxVisible, setPromoBoxVisible } = useSwapsStore();

        const { activePrestake } = usePrestakingStore();

        const searchString = ref('');

        const unclaimedCashlinkCount = ref(0);
        const showUnclaimedCashlinkList = ref(false);

        const address$ = ref<HTMLDivElement>(null);
        const addressMasked = ref<boolean>(false);

        const { isMobile, isFullDesktop, width: windowWidth } = useWindowSize();

        useElementResize(address$, () => {
            let addressWidth: number;
            if (isMobile.value) {
                addressWidth = 322;
            } else if (isFullDesktop.value) {
                addressWidth = 396;
            } else {
                addressWidth = 372; // Tablet
            }
            addressMasked.value = address$.value!.clientWidth < addressWidth;
        });

        function hideUnclaimedCashlinkList() {
            showUnclaimedCashlinkList.value = false;
        }

        function setUnclaimedCashlinkCount(count: number) {
            unclaimedCashlinkCount.value = count;
            if (!count) hideUnclaimedCashlinkList();
        }

        function clearSearchString() {
            searchString.value = '';
        }

        watch(activeAddress, (address, oldAddress) => {
            hideUnclaimedCashlinkList();
            clearSearchString();

            if (address !== oldAddress && promoBoxVisible) {
                setPromoBoxVisible(false);
            }
        });

        watch(activeCurrency, (currency, oldCurrency) => {
            if (currency !== oldCurrency && promoBoxVisible) {
                setPromoBoxVisible(false);
            }
        });

        function rescan() {
            const { addressSet } = useBtcAddressStore();
            checkHistory(
                addressSet.value.external,
                [],
                0,
                BTC_ADDRESS_GAP,
                console.error, // eslint-disable-line no-console
                true,
            );
            checkHistory(
                addressSet.value.internal,
                [],
                0,
                5,
                console.error, // eslint-disable-line no-console
                true,
            );
        }

        function onTransactionListScroll() {
            if (!promoBoxVisible.value) return;
            setPromoBoxVisible(false);
        }

        function toggleUnclaimedCashlinkList() {
            showUnclaimedCashlinkList.value = !showUnclaimedCashlinkList.value;
        }

        const { config } = useConfig();

        async function convertBridgedUsdcToNative() {
            let relayUrl: string;

            // eslint-disable-next-line no-async-promise-executor
            const request = new Promise<Omit<SignPolygonTransactionRequest, 'appName'>>(async (resolve, reject) => {
                try {
                    const [client, swapContract] = await Promise.all([
                        getPolygonClient(),
                        getSwapContract(),
                    ]);
                    const fromAddress = usdcAddressInfo.value!.address;

                    const [
                        usdcNonce,
                        forwarderNonce,
                        blockHeight,
                    ] = await Promise.all([
                        client.usdc.nonces(fromAddress) as Promise<BigNumber>,
                        swapContract.getNonce(fromAddress) as Promise<BigNumber>,
                        getPolygonBlockNumber(),
                    ]);

                    // eslint-disable-next-line @typescript-eslint/prefer-as-const
                    const method:/* 'swap' | */'swapWithApproval' = 'swapWithApproval';

                    const {
                        fee,
                        gasLimit,
                        gasPrice,
                        relay,
                    } = await calculateFee(config.usdc.usdcContract, method, undefined, swapContract);
                    relayUrl = relay.url;

                    if (fee.toNumber() >= usdcAddressInfo.value!.balance!) {
                        reject(new Error(i18n.t(
                            'You do not have enough USDC.e to pay conversion fees ({fee})',
                            { fee: `${fee.toNumber() / 1e6} USDC.e` },
                        ) as string));
                        return;
                    }

                    // Limit swap amount to 100k USDC.e, to not unbalance the pool too much
                    const amount = Math.min(100_000e6, usdcAddressInfo.value!.balance! - fee.toNumber());

                    // Only allow 0.5% slippage on mainnet, but up to 5% on testnet
                    const minTargetAmountPercentage = config.environment === ENV_MAIN ? 0.995 : 0.95;

                    const data = swapContract.interface.encodeFunctionData(method, [
                        /* address token */ config.usdc.usdcContract,
                        /* uint256 amount */ amount,
                        /* address pool */ config.usdc.swapPoolContract,
                        /* uint256 targetAmount */ Math.floor(amount * minTargetAmountPercentage),
                        /* uint256 fee */ fee,
                        ...(method === 'swapWithApproval' ? [
                            // // Approve the maximum possible amount so afterwards we can use the `swap` method for
                            // // lower fees
                            // /* uint256 approval */ client.ethers
                            //    .BigNumber.from('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'),
                            /* uint256 approval */ amount + fee.toNumber(),

                            /* bytes32 sigR */ '0x0000000000000000000000000000000000000000000000000000000000000000',
                            /* bytes32 sigS */ '0x0000000000000000000000000000000000000000000000000000000000000000',
                            /* uint8 sigV */ 0,
                        ] : []),
                    ]);

                    const relayRequest: RelayRequest = {
                        request: {
                            from: fromAddress,
                            to: config.usdc.swapContract,
                            data,
                            value: '0',
                            nonce: forwarderNonce.toString(),
                            gas: gasLimit.toString(),
                            validUntil: (blockHeight + 3000 + 3 * 60 * POLYGON_BLOCKS_PER_MINUTE)
                                .toString(10), // 3 hours + 3000 blocks (minimum relay expectancy)
                        },
                        relayData: {
                            gasPrice: gasPrice.toString(),
                            pctRelayFee: relay.pctRelayFee.toString(),
                            baseRelayFee: relay.baseRelayFee.toString(),
                            relayWorker: relay.relayWorkerAddress,
                            paymaster: config.usdc.swapContract,
                            paymasterData: '0x',
                            clientId: Math.floor(Math.random() * 1e6).toString(10),
                            forwarder: config.usdc.swapContract,
                        },
                    };

                    resolve({
                        ...relayRequest,
                        ...(method === 'swapWithApproval' ? {
                            approval: {
                                tokenNonce: usdcNonce.toNumber(),
                            },
                        } : null),
                    });
                } catch (e) {
                    reject(e);
                }
            }).catch((error) => {
                // Trigger alert only after popup closed, as otherwise the popup is visually blocking the alert
                // and the UI seems frozen
                window.setTimeout(() => {
                    alert(error.message); // eslint-disable-line no-alert
                }, 200);
                throw error;
            });

            const signedTransaction = await swapBridgedUsdcToNative(request).catch((error) => {
                // Trigger alert only after popup closed, as otherwise the popup is visually blocking the alert
                // and the UI seems frozen
                window.setTimeout(() => {
                    alert(error.message); // eslint-disable-line no-alert
                }, 200);
                throw error;
            });
            if (!signedTransaction) return false;

            const { relayData, ...relayRequest } = signedTransaction.message;
            const tx = await sendPolygonTransaction(
                { request: relayRequest as ForwardRequest, relayData },
                signedTransaction.signature,
                relayUrl!,
            ).catch((error) => {
                alert(error.message); // eslint-disable-line no-alert
            });

            if (tx) {
                useUsdcTransactionsStore().addTransactions([tx]);
            }

            return tx;
        }

        return {
            activeCurrency,
            searchString,
            activeAccountId,
            activeAddressInfo,
            onboard,
            rename,
            rescan,
            unclaimedCashlinkCount,
            setUnclaimedCashlinkCount,
            showUnclaimedCashlinkList,
            hideUnclaimedCashlinkList,
            btcAccountBalance,
            usdcAccountBalance,
            nativeUsdcAccountBalance,
            usdcAddressInfo,
            CryptoCurrency,
            promoBoxVisible,
            setPromoBoxVisible,
            onTransactionListScroll,
            address$,
            addressMasked,
            toggleUnclaimedCashlinkList,
            config,
            convertBridgedUsdcToNative,
            activePrestake,
            windowWidth,
        };
    },
    components: {
        ArrowRightSmallIcon,
        InfoCircleSmallIcon,
        Identicon,
        BitcoinIcon,
        GearIcon,
        RenameIcon,
        RefreshIcon,
        Copyable,
        Amount,
        FiatConvertedAmount,
        SearchBar,
        TransactionList,
        BtcTransactionList,
        UsdcTransactionList,
        ArrowLeftIcon,
        MenuDotsIcon,
        MobileActionBar,
        Portal,
        HighFiveIcon,
        BoxedArrowUpIcon,
        UsdcIcon,
        CashlinkButton,
        PrestakingButton,
        HeroIcon,
        PrestakingPreview,
    },
});
